.tweet {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 40vh; /* This makes the container take at least the full height of the viewport */
    padding: 2px;
  }
  
  /* Optional: Set a max-width for individual tweets */
  .tweet > * {
    max-width: 600px; /* Adjust as needed */
    width: 100%;
    margin-bottom: 20px; /* Space between tweets */
    margin-top: 0px;
  }
  .tweet-heading{
    margin-top: 40px;
    
  }
  @media (max-width: 918px) {
    .tweet {
      min-height: 30vh;
    }

  }
  @media (max-width: 1024px) {
    .tweet {
      min-height: 20vh;
    }

  }