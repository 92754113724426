.App {
  text-align: center;
}
body, html {
  text-align: center;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #333; /* Dark background color */
  color: #fff; /* Light text color for contrast */
  font-family: 'Arial', sans-serif; /* Optional: Set a default font */
}
#logo{
  height: 120px;
  margin-right: 10px;
  margin-left: 50px;
  position: relative;
  width: auto;
}
#cyano-heading{
  margin-top: 10px;
}
#title-heading{
  margin-bottom: 200px; 
  font-family: 'Arial', sans-serif;
  text-align: left;
}
#project-overview-container {
  display: flex;
  align-items: flex-start;
 
}


#video {
  flex: 1 1;
  padding: 1rem;
}


@media only screen and (max-width: 768px) {
  #project-overview-container {
    flex-direction: column;
  }

  #video {
    margin-top: 10px; /* Adjust margin as needed */
  }

}

.text-content {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}



@media (max-width: 767px) {

  #video {
    width:100px;
    height:100px;
   
  }
}



@media (max-width:632px)
{
#logo{
  height:80px;
}
}

@media(max-width:478px)
{
  #logo{
    margin-left: 0px;
    height:80px;
    margin-top: 100px;
  }
}
@media(max-width:345px)
{
  #logo{
    height:70px;
    margin-top: 100px;
  }
}

@media only screen and (min-width:769px) and (max-width:899px)
{
#logo{
  margin-top: 50px;
}
}
/* Media query for smaller devices */
@media (max-width: 768px) {
  #text-content {
    padding: 10px; /* Adjust padding for smaller devices */
  }

  #text-content p {
    font-size: 14px; /* Decrease font size for smaller devices */
  }
}

#project-overview-content{

border:0px;
  padding-top: 0px;
  margin-top: 0px;
  font-family:  sans-serif;
  
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#tweet-news-division{
  display: flex;
  flex-direction: row;
}
#tweet-news-division > * {
  flex: 1;
}
@media (max-width: 800px) {
  #tweet-news-division {
    flex-direction: column;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

