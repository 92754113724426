.youtube-video {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 0px;
}

.video-container iframe {
    position: relative;
    bottom: 80px;
    width: 800px;
    height: 400px;
}

.video-description {
    margin-top: 20px;
    margin-bottom: 20px;
}

@media (max-width: 1372px) and (min-width: 773px) {
    .video-container iframe {
        width: 600px;
    }
}

@media (max-width: 768px) {
    .youtube-video {
        margin-bottom: 10px;
    }

    .video-container iframe {
     
        left:100px;
       width:300px;
       height:200px;
    }

    .video-description {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}
