.custom-lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.custom-lightbox-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  background:none;
  padding: 20px;
  text-align: center;
}

.custom-lightbox-image {
  max-width: 100%;
  max-height: 80vh;
  transition: transform 0.3s ease;
}

.custom-lightbox-caption {
  margin-top: 10px;
  font-size: 16px;
  color: #fff;
}

.custom-lightbox-prev,
.custom-lightbox-next,
.custom-lightbox-close,
.custom-lightbox-zoom-in,
.custom-lightbox-zoom-out {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 20px;
  transition: background-color 0.3s;
}

.custom-lightbox-prev {
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.custom-lightbox-next {
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.custom-lightbox-close {
  top: 10px;
  right: 10px;
  color:#fff;
  background-color: red;
}

.custom-lightbox-zoom-in {
  bottom: 10px;
  right: 60px;
}

.custom-lightbox-zoom-out {
  bottom: 10px;
  right: 10px;
}

.custom-lightbox-prev:hover,
.custom-lightbox-next:hover,
.custom-lightbox-close:hover,
.custom-lightbox-zoom-in:hover,
.custom-lightbox-zoom-out:hover {
  background-color: rgba(0, 0, 0, 0.9);
}
