/* FormComponent.css */
#report-bloom-heading{
  margin-top: 130px;
  color:#e69a8dff; 
  font-size: 25px;
}
.form-container {
  max-width: 90%;
  margin: 10px 50px 30px 100px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #535559;
  border-radius: 10px;
  border: 1px solid #000000;
  box-shadow: 5px 10px #282626; 
}

.form-container form {
  width: 80%; /* Ensure form takes full width of container */
}
.form-container form input {
  margin-bottom: 20px;
  padding: 10px;
  margin-top: 5px;
}

label {
  display: block;
  margin-bottom: 20px;
}

input[type="text"], input[type="email"] {
  width: 50%;
  box-sizing: border-box;
}

#form-submit {
  font-size: 20px;
  border-radius: 10px;
  display: block;
  margin: 20px auto 10px;
  width: 30%
}


.form-container label{
  font-size: 20px;
  margin-bottom: 5px;
  margin-right: 20px;
  margin-top: 5px;
  padding: 3px;
  text-align: left;
    
   
}
input[type=date], input[type=email], input[type=text], textarea {
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 20px;
  outline: none;
  padding: 5px;
  transition: border-color .3s ease;
  width: calc(100% - 30px);
}

@media (max-width: 1200px) {
  .form-container input,
  .form-container button {
    max-width: 600px; /* Adjust max-width for smaller screens */
  }
}

@media (max-width: 992px) {
  .form-container input,
  .form-container button {
    max-width: 600px; /* Adjust max-width for medium screens */
  }
}

@media (max-width: 768px) {
  .form-container input,
  .form-container button {
    max-width: 500px; /* Adjust max-width for smaller devices */
  }
}

@media (max-width: 600px) and (min-width: 170px) {
  .form-container {
    width: 90%;
    margin: 0 auto;
  }

  input[type="text"],
  input[type="email"] {
    width: 100%;
    box-sizing: border-box; /* Include padding and border in the element's total width */
    margin-bottom: 10px; /* Adjust as needed */
  }

  #form-submit {
    width: 100%;
    box-sizing: border-box; /* Include padding and border in the element's total width */
  }
}

#report-bloom-heading{
  font-size: 30px;
}
#report{
  text-align: center;
  margin-bottom: 20px;
  margin-top: 100px;
  color: #fff;
}

.form-container form {
  display: flex;
  flex-direction: column;
}


.form-container button[type="submit"] {
  background-color: #099509;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  padding: 15px;
  transition: background-color .3s ease
}

.form-container button[type="submit"]:hover {
  background-color:  #e69a8dff; 
}


input[type="file"]::file-selector-button {
  border:none;
  padding: 0.2em 0.4em;
  border-radius: 5px;
  cursor: pointer;
  background-color: #099509;
  transition: .3s ease;
  color:white;
  font-size: 16px;

}

.form-container label input[type="file"] {
  font-family: Arial, sans-serif; 
  font-size: 16px; 
}


input[type="file"]::-ms-browse:hover {
  background-color: #e69a8dff; 
  border: 2px solid white;
}

input[type="file"]::-webkit-file-upload-button:hover {
  background-color: #e69a8dff; 
  border: 2px solid white
}

input[type="file"]::file-selector-button:hover {
  background-color: #e69a8dff; 
  border: 2px solid white;
}
