.sponsor-display {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 5px;
    margin: 0px 10px
  }
  
  .sponsor {
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc; 
    padding-bottom: 10px;
  }
  
  .sponsor:last-child {
    border-bottom: none; 
  }
  
  .sponsor-header {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    margin-top: 10px;
  }
  
  .sponsor-logo {
    width: 100px; 
    height: auto; 
    margin-right: 10px;
  }
  
  .sponsor-name {
    font-weight: bold;
  }
  
  .sponsor-program {
    margin-left: 10px;
    font-size: small;
  }
  
  